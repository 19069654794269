import React, { useMemo, useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useClient, useUserContext } from "./hooks";
import { FormGroup, SubmitButton } from "./components";
import { toast } from "react-toastify";
import { DATE_FORMAT, DATETIME_PARSE } from "./constants";
import { format, parse } from "date-fns";

export const Settings = () => {
  const [ preference, setPreference ] = useState(true);
  const { user, query } = useUserContext();
  const { customer, user: _user } = user;
  const methods = useForm({
    defaultValues: customer,
  });
  
  const { register, handleSubmit } = methods;
  const client = useClient();
  useEffect(() => {
    if (customer?.id) {
      retrievePreferences();
    }
  }, [customer?.id]);

  const handleChange = () => {
    setPreference(!preference);
  };

  async function retrievePreferences(){
    try {
      const { status, data } = await client
        .get("customer-preferences", {
          params: { customer_id: customer.id},
        })

      if (status !== 200) {
        console.log("200");
      }
      if(data && data.length > 0){
        console.log(data);
        const preferenceValue = data[0].value === "1";
        setPreference(preferenceValue);
        methods.setValue("preference", preferenceValue);
      } else {
        setPreference(false);
      };
    } catch (ex) {
        console.log('broken');
      };
  }
  

  async function onSubmit({ preference }) {
    try {
      const resp = await client
        .post("update-customer-preferences", {
          customer_id: customer.id,
          preferences: [{
            id: 1,
            value: preference,
          }],
        })
        .get("data");

      if (resp?.status !== 200) {
        toast.error("Could not update preferences at this time");
        return;
      }

      await query.refetch({ throwOnError: true });
      toast.success("Preferences updated successfully");
    } catch (ex) {
      toast.error("An error occurred");
    }
  }

  return (
    <FormProvider {...methods}>
      <h2 className="element-title mb-0">Preferences</h2>
      <p></p>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormGroup name="marketing_preference" label="Stay up to date with our latest news and exclusive offers:">
          <input
            type="checkbox"
            {...register("preference")}
            checked={preference}
            onChange={(e) => {
              handleChange();
              methods.setValue("preference", e.target.checked);
            }}
            style={{ height: '30px', width: '30px' }}
          />
        </FormGroup>
        <div className="tw-flex tw-justify-end">
          <SubmitButton>Update</SubmitButton>
        </div>
      </form>
    </FormProvider>
  );
};
