import { useState, createContext, useContext } from "react";
import { Link, NavLink as BaseNavLink } from "react-router-dom";
import logo from "../assets/img/logo.png";
import { Navbar, NavbarToggler, Nav, Collapse, NavItem } from "reactstrap";
import { useUserContext } from "../hooks";
import { HOME_URL } from "../constants";

export const BaseA = ({ children, bottom }) => {
  return (
    <div className="login-wrapper">
      <div className="content">
        <div className="head">
          <Link to={HOME_URL}>
            <img src={logo} alt="Premium Car Parks" />
          </Link>
        </div>
        <div className="center">{children}</div>
        {bottom ? <div className="mt-4 bottom">{bottom}</div> : null}
      </div>
      <div className="image login-image" />
    </div>
  );
};

const NavLink = props => {
  const { setIsOpen } = useContext(MenuContext);

  return <BaseNavLink onClick={() => {
    setIsOpen(false);
  }} {...props} />
};

const MenuContext = createContext();

export const BaseB = ({ children }) => {
  const [isOpen, setIsOpen] = useState();
  const { user } = useUserContext();

  return (
    <div className="tw-min-h-screen form-page">
      <MenuContext.Provider value={{ isOpen, setIsOpen }}>
        <header id="header">
          <Navbar expand="lg">
            <Link className="navbar-brand" to={HOME_URL}>
              <img src={logo} alt="Logo" />
            </Link>
            {user ? (
              <>
                <NavbarToggler onClick={() => setIsOpen((v) => !v)}>
                  <i className="fal fa-bars" />
                </NavbarToggler>
                <Collapse isOpen={isOpen} navbar>
                  <Nav className="ml-auto mr-0 navbar-nav">
                    <NavItem>
                      <NavLink to="my_details" className="nav-link">
                        My details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="registrations" className="nav-link">
                        Registrations
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="parking_history" className="nav-link">
                        Parking history
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="subscriptions" className="nav-link">
                        Subscriptions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="invoices" className="nav-link">
                        Invoices
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="credits" className="nav-link">
                        Credits
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="contact_us" className="nav-link">
                        Contact
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="settings" className="nav-link">
                        Settings
                      </NavLink>
                    </NavItem>
                    <NavItem className="nav-btn">
                      <Link to="logout" className="nav-link">
                        Logout
                      </Link>
                    </NavItem>
                  </Nav>
                </Collapse>
              </>
            ) : null}
          </Navbar>
        </header>
      </MenuContext.Provider>

      <div className="form-section">
        <div className="container">
          <div className="row">{children}</div>
        </div>
      </div>
    </div>
  );
};
